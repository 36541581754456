import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import DatePicker from 'react-datetime';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { FcCalendar } from 'react-icons/fc';
import { searchLoan } from '../../../actions/loan_actions';
import { Homeacc_prvdrLogoselector, format_date_tbl, getValueFromLangJson, lbl_date, rt_algn } from '../../../helpers/common_helper';
import {lbl_amt} from './../../../helpers/product_helper';
import { dd_value } from '../../../actions/common_actions';
import { Link } from 'react-router-dom';
import ViewTransactions from '../../loan/component/ViewTransactions';
import CommonReactTable from '../../../helpers/commonReact_table';
import { check_priv } from '../../../helpers/storage_helper';
import { BasicReactTable } from '../../../helpers/react_table';
import { v4 as uuidv4 } from 'uuid';
import DataGridTable from '../../CommonComponent/table';
import { Box } from '@mui/material';
import ExportCsv from '../../common/component/ExportCsv';

class Overduereport extends FlowComponent {
    state = {
      getreports:{"voided_fa" : "false",status:"Overdue"},
      row_datas:[],
      column_data:[],
      copy:[],
      loader:false,
      page: { page: 0, pageSize: 10 },


      }

      componentWillMount(){
        this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req ,status: "enabled", biz_account: true}, ['acc_prvdr_code', 'name'], "acc_prvdr");
        this.load_flow_rel_mgr();
      }

      handleSearch = () => {	
        this.setState({loader:true})
        this.setState({copy:[],row_datas:[],column_data:[]})
        var mode="searchbtn"
        const loan_search_criteria = this.state.getreports;
        loan_search_criteria.mode=mode;
        
          searchLoan(this.req({loan_search_criteria}, true))
                .then((response) => {
                  this.resp(response)
                  this.setState({search_btn_style : ""});
                if(!response){
                this.setState({loader:false})
                  return
                 };    
                  if(response.data.mode == 'search'){
                    let data =  response.data.results?.length>0 ? response.data.results?.map((row) => ({
                      ...row,
                      id: uuidv4(), 
                  })) : []; 
								    this.setState(state=>state.copy = data)
                    this.setState(state=>state.row_datas = data)
                    this.setState(state=>state.column_data = this.columndatas())
                    this.setState({loader:false})              
    
                       }else{
                        this.setState({loader:false})
                        alert("No results found.");   		
                       }
                  }
                );			        
      }
      columndatas= ()=> {
        return [
          {
            headerName : getValueFromLangJson('fa_id'), 
            field: "loan_doc_id",		
            align: 'center',headerAlign: 'center',  flex: 1,
		        minWidth:200,
            renderCell: (params) => <Link className='link-doc-id' to={"/fa/view/"+params.row.loan_doc_id} target="_blank">{params.row.loan_doc_id}</Link>			
          },
          {
            headerName : getValueFromLangJson('ac_number'),
            field: "acc_number",
            align: 'center',headerAlign: 'center',  flex: 1,
		        minWidth:200,

            renderCell:params => <div className='d-flex align-items-center'> 
            {/* <Homeacc_prvdrLogoselector logotype={params.row.acc_prvdr_code}/>  */}
            <div className='d-flex' style={{height:10,width:30,}}>
              <img className="img-fluid" style={{maxWidth:'50px'}} src={`/img/${params.row.acc_prvdr_code}_logo_color.png`} alt={params.row.acc_prvdr_code}/>			
            </div>
            <p className='no-margin' style={{paddingLeft:'20px'}}> {params.row.acc_number}</p>  </div> 
          },
          {
            headerName : getValueFromLangJson("rm_label_short"),
            field: "flow_rel_mgr_name",
            align: 'center',headerAlign: 'center',  flex: 1,
		        minWidth:200,

            renderCell:params => <p className='text-center m-0'>{params.row.flow_rel_mgr_name}</p> 
          },
          {
            headerName :getValueFromLangJson("bz_name") + " | " +getValueFromLangJson('cust_name') ,
            field: "biz_name",
            align: 'center',headerAlign: 'center',  flex: 1,
		        minWidth:200,
            renderCell:params =>  <p className='text-center m-0'>
            {params.row.cust_name}
            <br />
            {params.row.biz_name}
          </p>
          },
          {
            headerName : getValueFromLangJson("reg_date"),
            field: "reg_date",
            minWidth:200,

            align: 'center',headerAlign: 'center',  flex: 1,
            renderCell:params =><p className='text-center m-0'>{format_date_tbl(params.row.reg_date, true)}</p> 
          },
          {
            headerName :getValueFromLangJson("disb_date"),
            field: "disbursal_date",
            align: 'center',headerAlign: 'center',  flex: 1,
		        minWidth:200,

            renderCell:params =><p className='text-center m-0'>{moment(params.row.disbursal_date).format('DD MMM YYYY')}</p> 
          },
          {
            headerName :getValueFromLangJson("due_date"),
            field: "due_date",
            align: 'center',headerAlign: 'center',  flex: 1,
		        minWidth:200,

            renderCell:params => <p className='text-center m-0'>{format_date_tbl(params.row.due_date , true)}</p>
          },
          {
            headerName : getValueFromLangJson("last_visit_date"),
            field: "last_visit_date",
            align: 'center',headerAlign: 'center',  flex: 1,
		        minWidth:200,

            renderCell:params =><p className='text-center m-0'>{format_date_tbl(params.row.last_visit_date, true)}</p> 
          },
          {
            headerName : getValueFromLangJson("fa_amount") + " (" + this.currency_code +")", 
            field: "loan_principal",
            align: 'center',headerAlign: 'center',  flex: 1,
		        minWidth:200,
            renderCell:params => <div className='d-flex justify-content-center'>{ (lbl_amt(params.row.loan_principal))}</div>,
          },
          {
            headerName : getValueFromLangJson("fee") +  " (" + this.currency_code +")",
            field: "flow_fee",
            align: 'center',headerAlign: 'center',  flex: 1,
		        minWidth:200,

            renderCell:params => <div className='d-flex justify-content-center'>{ (lbl_amt(params.row.flow_fee))},</div>
          },
          {
            headerName : 'OS Amount' + " (" + this.currency_code +")",
            field: "current_os_amount",
            align: 'center',headerAlign: 'center',  flex: 1,
		        minWidth:200,

            renderCell:params => <div className='d-flex justify-content-center'>{ (lbl_amt(params.row.current_os_amount))}</div>,
          },
          {
            headerName : getValueFromLangJson("paid_amount") + " (" + this.currency_code +")",
            field: "paid_amount",
            align: 'center',headerAlign: 'center',  flex: 1,
		        minWidth:200,

            renderCell:params => <div className='d-flex justify-content-center'>{ (lbl_amt(params.row.paid_amount))}</div>,
          },
            {
              headerName :getValueFromLangJson("channel") ,
              field: "channel",
              renderCell:params => <div className='d-flex justify-content-center'>{dd_value(params.row.channel,'channel')}</div>,
              align: 'center',headerAlign: 'center',  flex: 1,
              minWidth:200,
              style : {textAlign:"center"}
              },
            {
              headerName :getValueFromLangJson('overdue_days'),
              field: "overdue_days",
              align: 'center',headerAlign: 'center',  flex: 1,
              minWidth:200,

              renderCell:params => <div className='d-flex justify-content-center'>{params. row.overdue_days}</div>,
              style : {textAlign:"center"}
            },
            {
              headerName :getValueFromLangJson('action') ,
              field: "action",
              minWidth:200,

              renderCell:params => <div className='d-flex justify-content-center p-1'>{<ViewTransactions parent="loan_search" loan_doc_id={params.row.loan_doc_id}/>}</div>,
              align: 'center',headerAlign: 'center',  flex: 1,
            },
            ]
        }
      handleFilter = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        this.setState(prevState => ({
          ...prevState,getreports: {...prevState.getreports,[id]: value}
        }));
      }
      handleDateChange = (property, date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        this.setState(prevState => ({
          getreports: {
            ...prevState.getreports,
            [property]: formattedDate
          }
        }));
      }
      handleSubmit = (event) => {
        const { disbursal_date__from, disbursal_date__to } = this.state.getreports;
        if (!disbursal_date__from || !disbursal_date__to || disbursal_date__from === "Invalid date" || disbursal_date__to === "Invalid date") {
          alert(getValueFromLangJson("please_fill_all_the_mandatory_fields"));
          return;
        }
      
        if (disbursal_date__from > disbursal_date__to) {
          alert(getValueFromLangJson("please_enter_a_date_range_in_correct_order"));
          return;
        }
        this.handleSearch();
      }
      

     
    render() { 
        return (<>
        {check_priv("report","overdue_report")&&
          <div className='mt-5'>
          <h4 className='px-1'>{getValueFromLangJson('overdue_fas_report')}</h4>
        
        <div className='row mt-5 d-flex align-items-end '>
        <div className='col-sm-12 col-md-3 col-lg-2 '>
              <div className=' m-2 row pl-0'>
              <label className='col p-0 sizem' >{getValueFromLangJson('disbursed_from')}<span className='product_red_star'>*</span></label>
              <label className='col-2 p-0 product_textr' htmlFor='start_date'><FcCalendar size={30}/></label>
              <DatePicker className='col-12 pr-1 p-0' inputProps={{ id: 'start_date', name: 'start_date',autoComplete:'off' }} defaultValue={this.state.disbursal_date__from} closeOnSelect={true}  onChange={(date) => this.handleDateChange('disbursal_date__from', date)}   dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
              
              </div>
            </div>
             <div className='col-sm-12 col-md-3 col-lg-2 '>
            <div className=' m-2 row pl-0'>
                <label className='col p-0 sizem' >{getValueFromLangJson("disbursed_to")}<span className='product_red_star'>*</span></label> 
                <label className='col-2 p-0 product_textr' htmlFor='end_date'><FcCalendar size={30}/></label>
                <DatePicker className='col-12 pr-1 p-0' inputProps={{ id: 'end_date', name: 'end_date' ,autoComplete:'off'}} defaultValue={this.state.disbursal_date__to}  closeOnSelect={true}   onChange={(date) => this.handleDateChange('disbursal_date__to', date)}  dateFormat="DD MMM YYYY" timeFormat={false} required="required" />  
            </div>
            </div>
            <div className={`col-sm-12 col-md-3 col-lg-2  `}>
             <div className=' m-2 row pl-0'>
                <label className='col-12 p-0 sizem' >{getValueFromLangJson('account_provider')}</label>
                <div className='w-100 col-12 m-0 p-0'>
                <Form.Control className='mt-1 col-12 p-0' as="select" id="acc_prvdr_code" defaultValue={this.state.dropdown_value}  onChange={(e) =>this.handleFilter(e)}>          
                  <option  value={""}>All</option>
                  {this.state.acc_prvdr_dd}
	              </Form.Control>
                </div>
              </div>
              
            </div>
            <div className={`col-sm-12 col-md-3 col-lg-2 `}>
             <div className=' m-2 row pl-0'>
                <label className='col-12 p-0 sizem' >{getValueFromLangJson("flow_rel_mgr")}</label>
                <div className='w-100 col-12 m-0 p-0'>
                <Form.Control className='mt-1 col-12 p-0' as="select" id="flow_rel_mgr_id" defaultValue={this.state.dropdown_value}  onChange={(e) =>this.handleFilter(e)}>          
                  <option  value={""}>All</option>
                  {this.state.flow_rel_mgr_dd} 
	              </Form.Control>
                </div>
              </div>
              
            </div>
            <div className={'col-md-3 text-center mt-5'}>
                <button onClick={this.handleSubmit} className='btn btn-md product_takeup_submit' disabled={this.state.calling_api}>{getValueFromLangJson("search")}</button>
            </div>
            </div>
           { this.state.row_datas&& this.state.row_datas.length>0&&!this.state.loader&&this.state.column_data?.length>0 &&
           <div className='Overdue Report mt-5'>

            {/* <CommonReactTable showPag={true} defaultPageSize={10} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.column_data} resizable={false} defaultSorted={[{id:"disbursal_date",desc:true}]} exportCSV={true} csvFile={"Overdue Report"} csvData={this.state.copy} searchData={[{...this.state.getreports,title:'Search Float Advance'}]}/>  */}

            {/* <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.column_data}  pagination={this.state.copy.length > 9 ? true : false} default_page_size = {10} csv_file={true} defaultSorted={[{id:"disbursal_date",desc:true}]} searchData={[{...this.state.getreports,title:'Search Float Advance'}]} FName={"Overdue Report"} />  */}

            <Box display={'flex'} justifyContent={'end'}>
						<ExportCsv
						export_csv_datas={ this.state.copy??[] }
						csvFileName = {'Overdue Report'}
						paginationMode= {"client"}
            columns={this.state.column_data??[]}

						/>
					</Box>

            <DataGridTable
                    rows={this.state.copy??[] }
                    columns={this.state.column_data??[] }
                    paginationMode="client"
                    sortingMode="client"
                    rowCount={this.state.copy?.length  ?? 0}
                    page={this.state.page}
                    setpage={(newPage) => this.setState({ page: newPage })}
                    isLoading={undefined}
                    isError={false}
                    errorText=""
                    noOfColumnsToLoad={10}
                />  

            </div>
            }
             {this.state.loader&&<>
                <p className='text-light mt-5 text-center' >{getValueFromLangJson('loading')}...</p>
                <div className="product_takeup_loader "></div></>
              }
            


            </div>}</>


             
            );
    }
}
 
export default Overduereport;